// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function gtm(event: string, params?: any): void {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  if (params) {
    window.dataLayer.push({ event: event, params: params });
  } else {
    window.dataLayer.push({ event });
  }
}

import { SendOutlined } from '@ant-design/icons';
import { Button, Form, Input, message } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PanelContent, PanelWhite } from '../../../components/Layout/styles';
import { Typography } from '../../../components/Typography/Typography';
import { firebaseFunctions } from '../../../firebase/firebase';
import { FirebaseCallableFunctions, FunctionAskQuestion } from '../../../firebase/firebase.models';
import { Offer } from '../../../models/offers.model';

const Header = styled.div`
  display: flex;
  align-items: center;
  background-color: #4caf50;
  padding: 20px;
`;

const Title = styled(Typography.H4)`
  color: ${({ theme }) => theme.textDarkPrimary};
`;

const SubTitle = styled(Typography.H6)`
  color: ${({ theme }) => theme.textDarkPrimary};
`;

const StyledIcon = styled.div`
  color: ${({ theme }) => theme.textDarkPrimary};
  margin-right: 16px;
  font-size: 32px;
`;

interface Props {
  currentUser: any;
  offer: Offer;
  disabled: boolean;
}

interface FormValue {
  title?: string;
  description?: string;
}

export const Message: React.FC<Props> = ({ currentUser, offer, disabled }) => {
  const [formValue, setFormValue] = useState<FormValue>({});
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const handleSubmit = () => {
    if (!formValue.title || !formValue.description) {
      message.error('Wypełnij wszystkie pola formularza.');
      return;
    }
    setLoading(true);

    const onAskQuestion = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.ON_ASK_QUESTION);
    const functionOnAskQuestionPayload: FunctionAskQuestion = {
      offerName: offer.name,
      title: formValue.title,
      description: formValue.description,
      tenant: currentUser.email,
      offerId: offer.id,
    };
    onAskQuestion(functionOnAskQuestionPayload)
      .then(() => {
        console.log('Message sent');
        message.success(t('FORMS.SEND_QUESTION.MESSAGE_SENT'));
        setFormValue({});
        form.resetFields();
        // gtm('generate_lead', {
        //   currency: 'PLN',
        //   value: 100,
        //   lead_source: 'Wiadomość do wynajmującego',
        // });
      })
      .catch((e) => {
        console.log('Message sent error', e);
        message.error(t('FORMS.SEND_QUESTION.ERROR_MESSAGE_NOT_SENT'));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <PanelWhite bodyFill>
      <Header>
        <StyledIcon>
          <i className="fa fa-comments-o" />
        </StyledIcon>
        <div>
          <Title>{t('FORMS.ASK_QUESTION_MESSAGE.HEADING')}</Title>
          <SubTitle>{t('FORMS.ASK_QUESTION_MESSAGE.DESCRIPTION')}</SubTitle>
        </div>
      </Header>
      <PanelContent>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={formValue}
          onValuesChange={(_changedValues, allValues) => setFormValue(allValues)}>
          <Form.Item
            name="title"
            label={t('FORMS.ASK_OUESTION.SUBJECT')}
            rules={[{ required: true, message: t('VALIDATION.FIELD_REQUIRED') }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label={t('FORMS.ASK_OUESTION.DESCRIPTION')}
            rules={[{ required: true, message: t('VALIDATION.FIELD_REQUIRED') }]}>
            <Input.TextArea rows={5} style={{ minHeight: 400 }} />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={disabled}
              loading={loading}
              icon={<SendOutlined />}
              className="w-full mt-4">
              {disabled ? t('FORMS.SEND_QUESTION.FAILURE') : t('FORMS.SEND_QUESTION.SUCCESS')}
            </Button>
          </Form.Item>
        </Form>
      </PanelContent>
    </PanelWhite>
  );
};

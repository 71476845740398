import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { ProgressConfigType } from '../../components/OfferRelatedWrapper/OfferProgress';
import { OfferRelatedWrapper } from '../../components/OfferRelatedWrapper/OfferRelatedWrapper';
import { Typography } from '../../components/Typography/Typography';
import { onLogOfferSubmitConfirm } from '../../events/hooks/onLogOfferSubmitConfirm.hook';
import { firebaseFunctions } from '../../firebase/firebase';
import { FirebaseCallableFunctions, FunctionSignOfferConfirmPayload } from '../../firebase/firebase.models';
import { useGetOffer } from '../../firebase/hooks/getOfferById.hook';
import { getTotalIncome } from '../../firebase/hooks/getTotalIncome.hook';
import { OfferCheck, useUpdateOfferCheck } from '../../firebase/hooks/updateOfferCheck.hook';
import { useGetOfferImages } from '../../hooks/getOfferImages.hook';
import { OfferStatusState } from '../../models/offers.model';
import { gtm } from '../../reportGTM';
import { App } from '../App/App';
import { useAuth } from '../Auth/AuthContext';
import { fetchUserData } from '../MojaTablica/Account/Identity/ConfirmIdentity/firebaseIdentityService';
import { createPDFs } from '../OfferAdd/Documents/createPDFs';
import { ConfigType } from '../OfferAdd/Documents/documents.models';
import { simulateStep } from '../OfferAdd/OfferAddConfirm';
import { AppRoutes } from '../Routing/routing.model';

import { ReceptionOfThePremises } from './components/ReceptionOfThePremises';
import { setOfferToUser } from './hooks/setOfferToUser.hook';

interface Props {
  userUid: string;
  offerId: string;
}

export const OfferSubmitConfirm: React.FC<Props> = ({ userUid, offerId }) => {
  const { isAuthenticating, isAuth } = useAuth();
  return <App>{!isAuthenticating && isAuth ? <WhenAuth offerId={offerId} userUid={userUid} /> : <WhenNotAuth />}</App>;
};

const WhenAuth: React.FC<Props> = ({ userUid, offerId }) => {
  const { push } = useHistory();
  const isLoading = useRef(false);
  const [offer] = useGetOffer(offerId);
  const imagesUrl = useGetOfferImages(offerId);
  const [offerAlreadySigned, setOfferAlreadySigned] = useState(false);
  const [isOperationCompleted, setIsOperationCompleted] = useState(false);
  const { currentUser } = useAuth();
  const [landlordData, setLandlordData] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      if (!currentUser?.uid) {
        return;
      }
      try {
        const data = await fetchUserData(currentUser.uid);
        if (isMounted) {
          setLandlordData(data);
        }
      } catch (error) {
        console.error('Error fetching landlord data:', error);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [currentUser?.uid]);

  useEffect(() => {
    const executeSteps = async () => {
      try {
        isLoading.current = true;
        // Fake step: Sprawdzanie bezpieczeństwa sieci
        await simulateStep(offerId, OfferCheck.CHECKING_NETWORK, 1000);

        // Fake step: Pobieranie potrzebnych danych
        await simulateStep(offerId, OfferCheck.DOWNLOADING_DATA, 1500);

        // Real step: Podpisywanie oferty
        useUpdateOfferCheck(offer.id, OfferCheck.SIGNING_CONTRACT);

        // await useUpdateOfferCheck(offerId, OfferCheck.VERIFYING_IMAGES);

        await createPDFs(offer, ConfigType.LEASE);

        // Fake step: Zapisywanie raportu
        await simulateStep(offerId, OfferCheck.SAVE_REPORT, 1600);

        // Przypisanie oferty użytkownikowi
        await setOfferToUser(userUid, offerId);

        // Logowanie potwierdzenia oferty
        await onLogOfferSubmitConfirm(offerId);

        // Real step: Wysyłanie podpisanej umowy
        useUpdateOfferCheck(offer.id, OfferCheck.SENDING_CONTRACT);
        const onOfferSignConfirm = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.ON_OFFER_SIGN_CONFIRM);
        const functionSignOfferConfirmPayload: FunctionSignOfferConfirmPayload = { userUid, offerId };
        await onOfferSignConfirm(functionSignOfferConfirmPayload);

        // Oznaczenie operacji jako zakończonej
        useUpdateOfferCheck(offer.id, OfferCheck.DONE);
        setIsOperationCompleted(true);
        const totalIncome = await getTotalIncome(offer.id);
        const price = typeof offer.price === 'string' ? parseFloat(offer.price) : offer.price;
        const formattedPrice = price && !isNaN(price) ? price.toFixed(2) : '0.00'; // Formatowanie na 2 miejsca po przecinku
        gtm('begin_checkout', {
          currency: 'PLN',
          value: totalIncome,
          items: [
            {
              item_id: offer.id,
              item_name: offer.name,
              price: parseFloat(formattedPrice), // Przesyłanie ceny jako liczba
              affiliation: landlordData?.company?.nazwa || '',
              item_category: offer.legalMainUse,
              location_id: offer.placeId,
            },
          ],
        });
      } catch (error) {
        console.error('Error during offer submission process:', error);
        setOfferAlreadySigned(true);
      } finally {
        isLoading.current = false;
      }
    };

    if (isLoading.current || isOperationCompleted) return;
    if (!offer) return;
    if (!userUid || offerId === undefined) {
      push(AppRoutes.HOME, { offerId });
      return;
    }

    if (offer.offerStatus.state === OfferStatusState.BOOKED) {
      executeSteps();
    } else {
      setOfferAlreadySigned(true);
    }
  }, [offer, offerId, userUid, isOperationCompleted, push]);

  return (
    <>
      {offer && (
        <OfferRelatedWrapper
          images={imagesUrl}
          offer={offer}
          description={
            <>
              <Typography.H5>
                {offerAlreadySigned ? t('RENTAL_AGREEMENT.RECEIVE_PROPERTY') : t('RENTAL_AGREEMENT.AGREEMENT_SIGNED')}
              </Typography.H5>
              <Typography.Body1>{t('RENTAL_AGREEMENT.AUTHORIZED_PERSON')}</Typography.Body1>
              <ReceptionOfThePremises id={offerId} offer={offer} />
            </>
          }
          progressConfigType={ProgressConfigType.SIGN_SUBMIT}></OfferRelatedWrapper>
      )}
    </>
  );
};

const WhenNotAuth: React.FC = () => {
  const { showSignInModal, closeModal } = useAuth();

  useEffect(() => {
    showSignInModal();
    return () => closeModal();
  }, []);

  return null;
};

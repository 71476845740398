import { UploadOutlined } from '@ant-design/icons';
import { Skeleton, Spin, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ToastVariant } from '../../../../../components/Toast/toast.model';
import { toastService } from '../../../../../components/Toast/toast.service';
import { firebaseFunctions, firebaseStorage } from '../../../../../firebase/firebase';
import { FirebaseCallableFunctions } from '../../../../../firebase/firebase.models';
import { gtm } from '../../../../../reportGTM';
import { SHOW_LOGS } from '../../../../App/App';
import { useAuth } from '../../../../Auth/AuthContext';
import { useDocumentModal } from '../../../../Documents/DocumentModalProvider';
import { uploadFile } from '../ConfirmIdentity/firebaseIdentityService';

const { Dragger } = Upload;
const FILE_NAME = 'Umocowanie.pdf';

interface AuthorityDraggerProps {
  show: boolean;
  companyId: string | null; // Receive companyId as a prop
  companyName: string | null; // Receive companyName as a prop
}

export const AuthorityDragger: React.FC<AuthorityDraggerProps> = ({ show = true, companyId, companyName }) => {
  const { currentUser } = useAuth();
  const { t } = useTranslation();
  const { showDocumentModal } = useDocumentModal();
  const [fileList, setFileList] = useState<any[] | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    if (!currentUser?.uid || !companyId) return;
    const loadFile = async () => {
      try {
        const downloadUrl = await firebaseStorage
          .ref(`/users/${currentUser.uid}/companies/${companyId}/${FILE_NAME}`)
          .getDownloadURL();
        if (downloadUrl) {
          setFileList([{ uid: '0', name: FILE_NAME, status: 'done', url: downloadUrl }]);
        } else {
          setFileList([]);
        }
      } catch (error) {
        SHOW_LOGS && console.error('Error loading authority file:', error);
        setFileList([]);
      }
    };
    loadFile();
    setIsLoading(false);
  }, [currentUser, companyId]);

  const customUpload = async ({ file, onError, onSuccess }: any) => {
    if (!currentUser?.uid || !companyId) {
      onError('User ID or Company ID is missing');
      return;
    }
    setIsUploading(true);
    try {
      const downloadUrl = await uploadFile(file, `users/${currentUser.uid}/companies/${companyId}/${FILE_NAME}`);
      if (downloadUrl) {
        const onSubmitCompany = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.ON_SUBMIT_COMPANY);
        await onSubmitCompany({ userId: currentUser.uid, companyId });
        setFileList([{ uid: '0', name: FILE_NAME, status: 'done', url: downloadUrl }]);
        onSuccess({ url: downloadUrl }, file);
        gtm('form_submit_reg_step_7');
        toastService.show(t('CONNECT_COMPANY.FILE_UPLOAD_SUCCESS'), t('SUCCESS'));
      } else {
        throw new Error('Failed to get download URL after uploading the file');
      }
    } catch (error) {
      SHOW_LOGS && console.error('Error during custom upload:', error);
      onError(error);
      toastService.show(t('CONNECT_COMPANY.FILE_UPLOAD_ERROR'), t('ERROR'), { variant: ToastVariant.ERROR });
    } finally {
      setIsUploading(false);
    }
  };

  if (!show) return null;

  return isLoading || fileList === null ? (
    <Skeleton
      active
      paragraph={{
        rows: 1,
      }}
    />
  ) : fileList[0]?.url ? (
    <a onClick={() => showDocumentModal(fileList[0].url)} className="text-blue-500">
      {t('VIEW_SIGNED_AUTHORITY_DOCUMENT')}
    </a>
  ) : (
    <Dragger customRequest={customUpload} showUploadList={true} disabled={!companyId}>
      <p className="ant-upload-drag-icon">
        {isUploading ? <Spin style={{ marginTop: '20px' }} /> : <UploadOutlined />}
      </p>
      <div className="ant-upload-text">
        {t('UPLOAD_AUTHORITY_DOCUMENT')} <div>{companyName}</div>
      </div>
    </Dragger>
  );
};

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PanelContent, PanelWhite } from '../../../components/Layout/styles';

const Text = styled.p`
  white-space: pre-line;
`;

interface Props {
  description: string;
}
export const Description: React.FC<Props> = ({ description }) => {
  const { t } = useTranslation();
  return (
    <PanelWhite header={t('DESCRIPTION')} collapsible bodyFill defaultExpanded>
      <PanelContent>
        <Text className="px-4 pb-4">{description}</Text>
      </PanelContent>
    </PanelWhite>
  );
};

import { Carousel, Panel } from 'rsuite';
import styled from 'styled-components';

import { Typography } from '../Typography/Typography';

export const grid = {
  sm: '480px',
  md: '992px',
  lg: '1200px',
  xl: '1280px',
};

export const WidthWrapper = styled.div`
  max-width: ${grid.xl};
  width: 100%;

  margin: 0 auto;
`;

export const Article = styled.article`
  padding: 80px 0;
`;

export const PanelWhite = styled(Panel)`
  border-radius: 8px;
  background-color: white;
`;
export const PanelContent = styled.div`
  padding: 0px;

  @media (min-width: 768px) {
    padding: 8px;
  }
`;
export const PanelBig = styled(Panel)`
  border-radius: 16px;
  background-color: white;
`;

export const Header = styled(Typography.H2)`
  text-align: center;
  margin-bottom: 12px;
`;
export const SubHeader = styled(Typography.H5)`
  text-align: center;
  color: #4e5c79;
  font-weight: 400;
`;
export const StyledCarousel = styled(Carousel)`
  background-color: transparent !important;
  height: auto;
  padding-bottom: 40px;
  .rs-carousel-slider-item {
    background-color: transparent !important;
  }
  .rs-carousel-label {
    background-color: ${({ theme }) => theme.backgroundGray};
  }
`;

export const PageHeadingPanel = styled(PanelBig)<{ shorten?: boolean }>`
  max-width: ${grid.lg};
  margin: -60px auto 0;
  position: relative;
  z-index: 1;
  transition: margin 0.3s ease;
  ${({ shorten }) => shorten && `margin-bottom: -134px;`};
`;

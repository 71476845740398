import { MenuProps, Space, Spin } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { FlexboxGrid } from 'rsuite';

import { WidthWrapper } from '../../../components/Layout/styles';
import { OfferCardHorizontal } from '../../../components/OfferCard/OfferCardHorizontal';
import { Typography } from '../../../components/Typography/Typography';
import { useGetBookedOffersByUid } from '../../../firebase/hooks/getBookedOffersByUid';
import { ItemListTypes } from '../../../models/googleAnalytics.model';
import { OfferStatusState } from '../../../models/offers.model';
import { App } from '../../App/App';
import { useAuth } from '../../Auth/AuthContext';
import { AppRoutes } from '../../Routing/routing.model';
import { MenuWrapper } from '../components/MenuWrapper';
import { additionalNavigation } from '../Menu/AdditionalNavigation';
import { MenuItems } from '../Menu/Menu';
import { StyledArticle } from '../MyOffers/MojaTablicaOffers';

export const BookedOffers: React.FC = () => {
  const { push } = useHistory();
  const { currentUser } = useAuth();
  const { t } = useTranslation();
  const onClick: MenuProps['onClick'] = (e) => {
    additionalNavigation(e.key);
    push(e.key);
  };

  const [offers, getBookedOffersByUid, loading] = useGetBookedOffersByUid();

  useEffect(() => {
    if (currentUser && currentUser.uid) {
      getBookedOffersByUid();
    }
  }, [currentUser, getBookedOffersByUid]);

  return (
    <App>
      <MenuWrapper>
        <MenuItems
          onClick={onClick}
          defaultSelectedKeys={[AppRoutes.MOJA_TABLICA_BOOKED_OFFERS]}
          defaultOpenKeys={['sub1']}
        />
      </MenuWrapper>

      {loading ? (
        <Spin style={{ margin: '50px' }} />
      ) : offers && offers.length > 0 ? (
        <StyledArticle>
          <WidthWrapper>
            <Space direction="vertical" style={{ marginLeft: 20 }}>
              <Typography.H6> {t('PORTFOLIO.RESERVED_OFFERS')}</Typography.H6>
            </Space>
            <FlexboxGrid>
              {offers.map((o, i) => (
                <FlexboxGrid.Item key={o.id + '-' + i} colspan={8}>
                  <OfferCardHorizontal
                    offer={o}
                    state={o.offerStatus.state as OfferStatusState}
                    addToFavoritesButton={false}
                    itemListType={ItemListTypes.RESERVED}
                  />
                </FlexboxGrid.Item>
              ))}
            </FlexboxGrid>
          </WidthWrapper>
        </StyledArticle>
      ) : (
        <Space direction="vertical" style={{ margin: 20 }}>
          {t('PORTFOLIO.NO_RESERVED_OFFERS')}
        </Space>
      )}
    </App>
  );
};

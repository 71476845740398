import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { HowItWorksTile } from '../../../components/HowItWorksTail/HowItWorksTile';
import { Header, SubHeader, WidthWrapper } from '../../../components/Layout/styles';

const Wrapper = styled.section`
  background-color: ${({ theme }) => theme.backgroundOrange};
  padding: 80px 5px;
`;
const DescriptionWrapper = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 60px;
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
`;
const Content = styled.article`
  display: flex;
  justify-content: space-evenly;

  @media (min-width: 768px) {
    > *:not(:last-child):after {
      content: url('/images/assets/arrow.png');
      position: absolute;
      width: auto;
      height: auto;
      background: 0 0;
      left: 100%;
      top: 40px;
    }
  }
`;
const StyledSubHeader = styled(SubHeader)`
  text-align: left;
`;

export const HowItWorks: React.FC = () => {
  const { t } = useTranslation();
  const steps = t('HOW_WORKS_OFFERS.STEPS_LIST', { returnObjects: true });
  return (
    <Wrapper>
      <WidthWrapper>
        <DescriptionWrapper>
          <Header>{t('HOW_WORKS_OFFERS.HEADING')}</Header>
          <StyledSubHeader>
            <ol>
              {' '}
              {Array.isArray(steps) &&
                steps.map((step, index) => (
                  <li key={index} className="my-3">
                    {step}
                  </li>
                ))}
            </ol>
          </StyledSubHeader>
        </DescriptionWrapper>
        <Content>
          <HowItWorksTile
            title={t('HOW_WORKS_OFFERS.SEARCH_OFFER.TITLE')}
            description={t('HOW_WORKS_OFFERS.SEARCH_OFFER.DESCRIPTION')}
            color={'green'}
            icon={'building-o'}
          />
          <HowItWorksTile
            title={t('HOW_WORKS_OFFERS.CHECK_REQUIREMENTS.TITLE')}
            description={t('HOW_WORKS_OFFERS.CHECK_REQUIREMENTS.DESCRIPTION')}
            color={'yellow'}
            icon={'check2'}
          />
          <HowItWorksTile
            title={t('HOW_WORKS_OFFERS.SIGN_LEASE.TITLE')}
            description={t('HOW_WORKS_OFFERS.SIGN_LEASE.DESCRIPTION')}
            color={'blue'}
            icon={'handshake-o'}
          />
        </Content>
      </WidthWrapper>
    </Wrapper>
  );
};

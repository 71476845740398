import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, InputNumber, Popover, Switch } from 'antd';
import MaskedInput from 'antd-mask-input';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { firebaseFunctions } from '../../../../../firebase/firebase';
import { FirebaseCallableFunctions } from '../../../../../firebase/firebase.models';
import { Verification } from '../../../../../models/offers.model';
import { gtm } from '../../../../../reportGTM';
import { SHOW_LOGS } from '../../../../App/App';

import { Company, CompanyData } from './company.models';

interface Props {
  initial: Company;
  isCompanyConfirmed: Verification;
  onFinish: (values: Company) => void;
  disabled?: boolean;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  companyId: string | null;
  formSubmitting?: boolean;
}

export const FormToLinkCompany: React.FC<Props> = ({
  initial,
  isCompanyConfirmed,
  onFinish,
  disabled,
  setDisabled,
  companyId,
  formSubmitting,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<Company>();
  const [companyData, setCompanyData] = useState<Company>(initial);
  const [iDontHaveKRS, setIDontHaveKRS] = useState(false);
  const [isCompanyInDatabase, setIsCompanyInDatabase] = useState(false);
  const [showFullForm, setShowFullForm] = useState(isCompanyConfirmed !== Verification.INITIAL || companyId !== '');
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    if (companyData?.numerKRS === '' && form.getFieldValue('nip') !== '') {
      setIDontHaveKRS(true);
    }
  }, [companyData, buttonLoading, form]);

  useEffect(() => {
    setShowFullForm(isCompanyConfirmed !== Verification.INITIAL || companyId?.length > 0);
  }, [isCompanyConfirmed, companyId]);

  useEffect(() => {
    form.setFieldsValue(companyData);
  }, [companyData, initial]);

  useEffect(() => {
    setCompanyData(initial);
    SHOW_LOGS && console.log('Initial data:', initial);
    // id initial jest puste === {}
    if (!Object.keys(initial).length) {
      SHOW_LOGS && console.log('Initial data is empty');
      // reset fields
      form.resetFields();
      form.setFieldsValue({ nip: '' });
    }
  }, [initial]);

  const checkCompanyInDatabase = useCallback(async (nip: string) => {
    const isCompanyInFirestore = firebaseFunctions.httpsCallable(
      FirebaseCallableFunctions.IS_COMPANY_ALREADY_IN_FIRESTORE
    );
    const response = await isCompanyInFirestore({ NIP: nip });
    return response.data as { exists: boolean; company?: CompanyData };
  }, []);

  const handleSwitchChange = (checked: boolean) => {
    setIDontHaveKRS(checked);
    form.validateFields();
    if (checked) {
      form.setFieldValue('numerKRS', '');
    }
  };

  const handleNIPSubmit = async () => {
    if (!form.getFieldValue('nip')) {
      form.validateFields();
      return;
    }
    setButtonLoading(true);
    try {
      const nip = form.getFieldValue('nip');
      const companyCheck = await checkCompanyInDatabase(nip);
      if (companyCheck.exists && companyCheck.company) {
        setIsCompanyInDatabase(true);
        setDisabled(false);
        setCompanyData(companyCheck.company.company);
      } else {
        await fetchCompanyDataFromRegonAPI(nip);
      }
      gtm('form_submit_reg_step_5');
      setShowFullForm(true);
    } catch (error) {
      console.error('Error in handleNIPSubmit:', error);
    } finally {
      setButtonLoading(false);
    }
  };

  const fetchCompanyDataFromRegonAPI = async (nip: string) => {
    try {
      const callRegonAPI = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.ON_CALL_REGON_API);
      const res = await callRegonAPI({ NIP: nip });
      if (res.data.status === 'ERROR' || res.data.resp?.ErrorCode === '4') {
        console.log('Company not found or API limit exceeded.');
      } else {
        const resp = res.data.resp;
        setCompanyData({
          nazwa: resp.Nazwa[0],
          regon: resp.Regon[0],
          kraj: 'POLSKA',
          kodPocztowy: resp.KodPocztowy[0],
          miejscowosc: resp.Miejscowosc[0].toUpperCase(),
          nrDomu: `${resp.NrNieruchomosci[0]}${resp.NrLokalu[0] ? ' / ' + resp.NrLokalu[0] : ''}`,
          ulica: resp.Ulica[0].toUpperCase(),
          numerKRS: form.getFieldValue('numerKRS'),
          nip,
          bankAccount: form.getFieldValue('bankAccount'),
        });
      }
    } catch (error) {
      console.error('Error fetching data from REGON API:', error);
    }
  };

  return (
    <>
      <Form
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 24 }}
        style={{ width: 600 }}
        onFinish={onFinish}
        initialValues={initial}
        form={form}
        disabled={disabled}>
        <Divider>{t('IDENTIFIERS')}</Divider>
        <Form.Item
          label={t('FORMS.CONNECT_COMPANY.NIP')}
          name="nip"
          rules={[{ required: true, message: t('VALIDATION.FIELD_REQUIRED') }]}>
          <InputNumber disabled={disabled} className="w-full" controls={false} />
        </Form.Item>
        {showFullForm && (
          <>
            <Form.Item
              label={t('FORMS.CONNECT_COMPANY.KRS')}
              name="numerKRS"
              rules={[{ required: !iDontHaveKRS, message: t('VALIDATION.KRS_REQUIRED') }]}>
              <Input disabled={disabled || iDontHaveKRS || isCompanyInDatabase} />
            </Form.Item>
            <Form.Item>
              <div className="justify-center items-center text-right -my-10">
                <Switch
                  className="m-2"
                  onChange={handleSwitchChange}
                  disabled={isCompanyInDatabase || disabled}
                  checked={iDontHaveKRS}
                />
                {t('VALIDATION.NO_KRS')}
                <Popover
                  trigger="hover"
                  content={
                    <div className="font-semibold w-96">
                      {t('KRS_POPOVER_1')}
                      <br />
                      <br />
                      {t('KRS_POPOVER_2')}
                    </div>
                  }>
                  <InfoCircleOutlined className="ml-2" />
                </Popover>
              </div>
            </Form.Item>
            <Form.Item label="REGON" name="regon" rules={[{ required: true, message: t('VALIDATION.FIELD_REQUIRED') }]}>
              <Input disabled={isCompanyInDatabase || disabled} />
            </Form.Item>
            <Form.Item
              label={t('FORMS.CONNECT_COMPANY.COMPANY_NAME')}
              name="nazwa"
              rules={[{ required: true, message: t('VALIDATION.FIELD_REQUIRED') }]}>
              <Input disabled={isCompanyInDatabase || disabled} />
            </Form.Item>
            <Divider>{t('FORMS.CONNECT_COMPANY.ADDRESS')}</Divider>
            {/* Address fields */}
            <Form.Item
              label={t('FORMS.CONNECT_COMPANY.COUNTRY')}
              name="kraj"
              rules={[{ required: true, message: t('VALIDATION.FIELD_REQUIRED') }]}>
              <Input disabled={isCompanyInDatabase || disabled} />
            </Form.Item>
            <Form.Item
              label={t('FORMS.CONNECT_COMPANY.POSTAL_CODE')}
              name="kodPocztowy"
              rules={[{ required: true, message: t('VALIDATION.FIELD_REQUIRED') }]}>
              <Input disabled={isCompanyInDatabase || disabled} />
            </Form.Item>
            <Form.Item
              label={t('FORMS.CONNECT_COMPANY.CITY')}
              name="miejscowosc"
              rules={[{ required: true, message: t('VALIDATION.FIELD_REQUIRED') }]}>
              <Input disabled={isCompanyInDatabase || disabled} />
            </Form.Item>
            <Form.Item
              label={t('FORMS.CONNECT_COMPANY.STREET')}
              name="ulica"
              rules={[{ required: true, message: t('VALIDATION.FIELD_REQUIRED') }]}>
              <Input disabled={isCompanyInDatabase || disabled} />
            </Form.Item>
            <Form.Item
              label={t('FORMS.CONNECT_COMPANY.HOUSE_NUMBER')}
              name="nrDomu"
              rules={[{ required: true, message: t('VALIDATION.FIELD_REQUIRED') }]}>
              <Input disabled={isCompanyInDatabase || disabled} />
            </Form.Item>
            <Form.Item
              label={<div>{t('BANK_ACCOUNT_NR')}</div>}
              name="bankAccount"
              rules={[
                { required: true, message: t('VALIDATION.FIELD_REQUIRED') },
                {
                  validator: (_, value) => {
                    const cleanedValue = value.replace(/\s/g, '');
                    return cleanedValue.length === 26
                      ? Promise.resolve()
                      : Promise.reject(new Error(t('VALIDATION.BANK_ACCOUNT_ERROR')));
                  },
                },
              ]}>
              <MaskedInput
                mask="00 0000 0000 0000 0000 0000 0000"
                placeholder="00 0000 0000 0000 0000 0000 0000"
                disabled={isCompanyInDatabase || disabled}
              />
            </Form.Item>
          </>
        )}
        <Form.Item wrapperCol={{ offset: 5, span: 16 }}>
          {!disabled && !companyId ? (
            <Button
              type="primary"
              htmlType={showFullForm ? 'submit' : undefined}
              onClick={!showFullForm ? handleNIPSubmit : undefined}
              loading={buttonLoading || formSubmitting}
              disabled={disabled}>
              {t(showFullForm ? 'SAVE' : 'NEXT')}
            </Button>
          ) : (
            formSubmitting && (
              <Button type="primary" loading={true}>
                {t('NEXT')}
              </Button>
            )
            // showFullForm && (
            //   // If the user is in creatorId array, the user is the creator of the company and can edit the company data
            //   // TODO: Add a check if the user is the creator of the company
            //   <Button disabled={true} loading={formSubmitting}>
            //     {t('EDIT')}
            //   </Button>
            // )
          )}
        </Form.Item>
      </Form>
    </>
  );
};

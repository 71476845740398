import firebase from 'firebase/app';
import { useEffect, useState } from 'react';

export const useInvoiceNumber = (): {
  invoiceNumbers: { landlord: number | null; tenant: number | null };
  loading: boolean;
} => {
  const db = firebase.firestore();
  // const [invoiceNumber, setInvoiceNumber] = useState(null);
  const [invoiceNumbers, setInvoiceNumbers] = useState<{ landlord: number | null; tenant: number | null }>({
    landlord: null,
    tenant: null,
  });
  const [loading, setLoading] = useState(true);

  const fetchInvoiceNumber = async () => {
    try {
      setLoading(true);

      const counterRef = db.collection('admin').doc('invoiceCounter');
      const counterDoc = await counterRef.get();

      // if (counterDoc.exists) {
      //   const currentCounter = counterDoc.data().last || 0;
      //   setInvoiceNumber(currentCounter);

      if (counterDoc.exists) {
        const currentCounter = counterDoc.data()?.last || 0;

        // Oblicz numer faktury dla właściciela (landlord) i najemcy (tenant)
        const landlordInvoiceNumber = currentCounter + 1; // Numer faktury dla landlorda
        const tenantInvoiceNumber = landlordInvoiceNumber + 1; // Numer faktury dla najemcy

        // Ustawienie numerów faktur w stanie
        setInvoiceNumbers({
          landlord: landlordInvoiceNumber,
          tenant: tenantInvoiceNumber,
        });
      } else {
        throw new Error('Invoice counter does not exist in the database.');
      }
    } catch (err) {
      console.error('Error fetching invoice number:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInvoiceNumber();
  }, []);

  // return { invoiceNumber, loading };
  return { invoiceNumbers, loading };
};

import firebase from 'firebase/app';
import { useMemo, useState } from 'react';

import { Tenant } from '../../models/legalEntity.model';
import { firestore } from '../firebase';
import { FirestoreCollection } from '../firebase.models';

export function useGetTenant(uid: string): [Tenant | null, boolean, firebase.FirebaseError | null] {
  const [error, setError] = useState<firebase.FirebaseError | null>(null);
  const [loading, setLoading] = useState(false);
  const [tenant, setTenant] = useState<Tenant | null>(null);

  useMemo(() => {
    setLoading(true);
    if (!uid) {
      setError({
        name: 'FirebaseError',
        code: 'auth/missing-tenant-uid',
        message: 'Missing tenant uid',
      });
      return [null, loading, error];
    } else {
      setError(null);
    }

    const unsubscribe = firestore
      .collection(FirestoreCollection.USERS)
      .doc(uid)
      .onSnapshot(
        (snap) => {
          const d = snap.data();

          if (d && d.company && typeof d.company.nazwa === 'string') {
            const tenant = {
              companyName: d.company.nazwa,
              lastName: d.lastName,
              name: d.firstName,
              NIP: d.company.nip,
            };
            setTenant(tenant as Tenant);
          } else {
            console.log("Missing 'company' object");
          }
          setLoading(false);
        },
        (e) => {
          setError(e);
          setLoading(false);
        }
      );
    return () => unsubscribe();
  }, [uid]);

  return [tenant, loading, error];
}

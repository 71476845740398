// src/components/DocumentViewer.tsx
import { Alert, Space, Spin } from 'antd';
import firebase from 'firebase/app';
import React, { useEffect, useState } from 'react';

export const DocumentViewer: React.FC<{ documentPath: string }> = ({ documentPath }) => {
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [isMobileSafari, setIsMobileSafari] = useState<boolean>(false);

  // Detect if the user is on Safari and on a mobile device
  useEffect(() => {
    const checkMobileSafari = () => {
      const ua = window.navigator.userAgent.toLowerCase();
      const isSafari = ua.includes('safari') && !ua.includes('chrome');
      const isMobile = /iPhone|iPad|iPod|Android/i.test(ua);
      setIsMobileSafari(isSafari && isMobile);
    };

    checkMobileSafari();
  }, []);

  useEffect(() => {
    const fetchDocument = async () => {
      const formattedDocumentPath = documentPath.replace('&', '/');
      try {
        const storage = firebase.storage();
        const fileRef = storage.ref(formattedDocumentPath);
        const url = await fileRef.getDownloadURL();
        setFileUrl(url);

        // Trigger download if it's Mobile Safari
        if (isMobileSafari) {
          window.location.href = url;
        }
      } catch (error) {
        setError('Failed to load the document.');
      } finally {
        setLoading(false);
      }
    };

    fetchDocument();
  }, [documentPath, isMobileSafari]);

  if (loading) {
    return (
      <Space
        direction="horizontal"
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Spin size="large" />
      </Space>
    );
  }

  if (error) {
    return <Alert message={error} type="error" />;
  }

  // For other devices, render iframe
  return (
    !isMobileSafari && (
      <div className="min-h-screen">
        <iframe src={fileUrl} width="100%" height="100%" title="Document Viewer" id="ifd"></iframe>
      </div>
    )
  );
};

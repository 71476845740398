import { ToastVariant } from '../../../components/Toast/toast.model';
import { toastService } from '../../../components/Toast/toast.service';
import { firebaseFunctions } from '../../../firebase/firebase';
import { FirebaseCallableFunctions } from '../../../firebase/firebase.models';
import { Offer } from '../../../models/offers.model';

import { configs } from './documents.models';

export async function createPDFs(offer: Offer, configType: keyof typeof configs): Promise<any> {
  const generatePdfAndUpload = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.GENERATE_OFFER_PDF_AND_UPLOAD);
  try {
    const response = await generatePdfAndUpload({ offerId: offer.id, configType: configType });
    return response.data.url;
  } catch (e: any) {
    toastService.show(e.message, 'Coś poszło nie tak.', {
      variant: ToastVariant.ERROR,
    });
    console.error(e);
    throw e;
  }
}

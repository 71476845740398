import { HeartFilled } from '@ant-design/icons';
import { Button, Card, message, Spin } from 'antd';
import Meta from 'antd/es/card/Meta';
import firebase from 'firebase/app';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { SHOW_LOGS } from '../../features/App/App';
import { useAuth } from '../../features/Auth/AuthContext';
import { fetchUserData } from '../../features/MojaTablica/Account/Identity/ConfirmIdentity/firebaseIdentityService';
import { AppRoutes } from '../../features/Routing/routing.model';
import { firebaseFunctions, firebaseStorage, firestore } from '../../firebase/firebase';
import { FirebaseCallableFunctions, FirestoreCollection } from '../../firebase/firebase.models';
import { getTotalIncome } from '../../firebase/hooks/getTotalIncome.hook';
import { useGetOfferImages } from '../../hooks/getOfferImages.hook';
import { ItemListTypeNames, ItemListTypes } from '../../models/googleAnalytics.model';
import { Offer, OfferStatusState, PostSigningStatus } from '../../models/offers.model';
import { gtm } from '../../reportGTM';
import { hashValue } from '../../utils/hash.utils';
import { ToastVariant } from '../Toast/toast.model';
import { toastService } from '../Toast/toast.service';

import { DeleteFavorite } from './components/DeleteFavorite';
import { DeleteOfferModal } from './components/DeleteOfferModal';
import { DocumentsOfferModal } from './components/DocumentsOfferModal';
import { DropdownButton } from './components/DropdownButton';
import { OfferBadges } from './components/OfferBadges';
import { OfferLocationButton } from './components/OfferLocationButton';
import { OfferPrice } from './components/OfferPrice';
import { RejectProtocolModal } from './components/RejectProtocolModal';
interface Props {
  offer: Offer;
  onLocationClick?: (id: string) => void;
  state?: OfferStatusState;
  addToFavoritesButton?: boolean;
  favorites?: Offer[];
  itemListType: ItemListTypes;
}
const baseHostname = window.location.hostname;
const port = baseHostname === 'localhost' ? ':3000' : '';
const httpOrHttps = port ? 'http' : 'https';

export const OfferCardHorizontal: React.FC<Props> = ({
  offer,
  onLocationClick,
  state = OfferStatusState.ACTIVE,
  addToFavoritesButton = true,
  itemListType,
}) => {
  const { isAuth, showSignInModal, currentUser } = useAuth();
  const { push } = useHistory();
  const { t } = useTranslation();
  const images = useGetOfferImages(offer.id, 0, true);
  const [heartColor, setheartColor] = useState('red');
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDocumentsModalOpen, setIsDocumentsModalOpen] = useState(false);
  const [isRejectProtocolModalOpen, setIsRejectProtocolModalOpen] = useState(false);
  const isLandlord = hashValue(currentUser?.uid) == offer.uid;
  const [landlordData, setLandlordData] = useState(null);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      if (!currentUser?.uid) {
        return;
      }
      try {
        const data = await fetchUserData(currentUser.uid);
        if (isMounted) {
          setLandlordData(data);
        }
      } catch (error) {
        console.error('Error fetching landlord data:', error);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [currentUser?.uid]);

  const redirectTo = useCallback(
    (path) => {
      push(path);
    },
    [push]
  );

  const handleFirestoreUpdate = useCallback(async (ref, newData) => {
    try {
      await ref.update(newData);
    } catch (error) {
      console.error('Error updating document: ', error);
    }
  }, []);

  const onShowOffer = useCallback(async () => {
    const destination = `${AppRoutes.OFFER_ROOT}/${offer.id}`;
    redirectTo(destination);
    const itemListTypeKey = ItemListTypes[itemListType] as keyof typeof ItemListTypeNames;
    const price = typeof offer.price === 'string' ? parseFloat(offer.price) : offer.price;
    const formattedPrice = price && !isNaN(price) ? price.toFixed(2) : '0.00'; // Formatowanie na 2 miejsca po przecinku
    gtm('select_item', {
      item_list_id: itemListType,
      item_list_name: ItemListTypeNames[itemListTypeKey],
      items: [
        {
          item_id: offer.id,
          item_name: offer.name,
          price: parseFloat(formattedPrice), // Przesyłanie ceny jako liczba
          affiliation: landlordData?.company?.nazwa || '',
          item_category: offer.legalMainUse,
          location_id: offer.placeId,
        },
      ],
    });
    const totalIncome = await getTotalIncome(offer.id);

    gtm('view_item', {
      currency: 'PLN',
      value: totalIncome,
      items: [
        {
          item_id: offer.id,
          item_name: offer.name,
          price: price,
          affiliation: landlordData?.company?.nazwa || '',
          item_category: offer.legalMainUse,
          location_id: offer.placeId,
        },
      ],
    });
  }, [isAuth, offer.id, t, landlordData]);

  const onEditOffer = useCallback(() => {
    redirectTo(AppRoutes.ADD_OFFER.replace(':id', offer.id));
  }, [offer.id]);

  const onDeleteOffer = useCallback(async () => {
    try {
      const offerRef = firestore.collection(FirestoreCollection.OFFERS).doc(offer.id);
      const userRef = firestore.collection(FirestoreCollection.USERS).doc(currentUser.uid);

      await handleFirestoreUpdate(offerRef, { 'offerStatus.state': OfferStatusState.DELETED });

      const doc = await userRef.get();
      if (!doc.exists) {
        console.warn(t('NO_DOCUMENT')); // No such document!
        return;
      }

      const companyId = doc.data().companyId;
      if (companyId && companyId.length > 0) {
        await deleteOfferFromCompany(companyId);
      } else {
        await deleteOfferFromUser(userRef, doc);
      }
    } catch (error) {
      console.error('Error deleting offer:', error);
    }
  }, [offer.id, t]);

  const deleteOfferFromCompany = async (companyId) => {
    const companyRef = firestore.collection(FirestoreCollection.COMPANIES).doc(companyId);
    const companyDoc = await companyRef.get();

    if (!companyDoc.exists) {
      console.warn(t('NO_DOCUMENT')); // No such document!
      return;
    }

    const updatedOffers = (companyDoc.data().offers || []).filter((item) => item !== offer.id);
    await handleFirestoreUpdate(companyRef, { offers: updatedOffers });
  };

  const deleteOfferFromUser = async (userRef, userDoc) => {
    const updatedOffers = (userDoc.data().offers || []).filter((item) => item !== offer.id);
    await handleFirestoreUpdate(userRef, { offers: updatedOffers });
  };

  const toggleModal = () => setIsModalOpen((prev) => !prev);

  const toggleDocumentsModal = () => setIsDocumentsModalOpen((prev) => !prev);
  const toggleRejectProtocolModal = () => setIsRejectProtocolModalOpen((prev) => !prev);

  const handleOk = () => {
    onDeleteOffer();
    toggleModal();
  };

  const onDuplicateOffer = useCallback(async () => {
    const hide = message.loading(t('OFFER.DUPLICATING'), 0); // Duplikowanie oferty...

    const originalDoc = await firestore.collection(FirestoreCollection.OFFERS_PUBLIC).doc(offer.id).get();

    if (!originalDoc.exists) {
      console.error(t('OFFER.OFFER_NOT_EXIST')); // Oferta nie istnieje!
      return;
    }

    const originalOfferRef = firestore.collection(FirestoreCollection.OFFERS_PUBLIC).doc(offer.id);
    const userRef = firestore.collection(FirestoreCollection.USERS).doc(currentUser.uid);

    try {
      const doc = await originalOfferRef.get();
      if (!doc.exists) {
        console.log(t('NO_DOCUMENT')); // No such document!
        return;
      }

      const offerData = Object.fromEntries(
        Object.entries({
          ...doc.data(),
          offerStatus: {
            state: OfferStatusState.UNSIGNED,
          },
          counteroffer: {
            selected: doc.data().counteroffer.selected,
          },
        }).filter(
          ([key]) =>
            ![
              'receptionOfThePremise',
              'receptionTime',
              'transferOfThePremise',
              'bookedBy',
              'signLandlordDate',
              'signTenantDate',
              'protocolData',
            ].includes(key)
        )
      );

      const docRef = await firestore.collection(FirestoreCollection.OFFERS).add(offerData);
      const newOfferId = docRef.id;
      console.log('newOfferId', newOfferId);

      docRef.update({ id: newOfferId });

      const originalOfferId = doc.id;
      const storageRef = firebaseStorage.ref();

      const copyImages = async (sourceFolderRef, destFolderRef) => {
        const imagesList = await sourceFolderRef.listAll();

        for (const image of imagesList.items) {
          const imageBlob = await image.getDownloadURL().then((url) => fetch(url).then((res) => res.blob()));
          const newImageRef = destFolderRef.child(image.name);
          await newImageRef.put(imageBlob);
        }
      };

      const originalImagesFolderRef = storageRef.child(`offers/${originalOfferId}/images`);
      const newImagesFolderRef = storageRef.child(`offers/${newOfferId}/images`);
      await copyImages(originalImagesFolderRef, newImagesFolderRef);

      const originalSmallImagesFolderRef = storageRef.child(`offers/${originalOfferId}/images/small`);
      const newSmallImagesFolderRef = storageRef.child(`offers/${newOfferId}/images/small`);
      await copyImages(originalSmallImagesFolderRef, newSmallImagesFolderRef);

      const userDoc = await userRef.get();
      if (!userDoc.exists) {
        console.log(t('NO_DOCUMENT')); // No such user document!
        return;
      }

      if (userDoc.data()?.companyId) {
        const addOfferToCompany = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.ADD_OFFER_TO_COMPANY);
        const resp = await addOfferToCompany({ companyId: userDoc.data().companyId, offerId: newOfferId });
        if (!resp.data.success) {
          message.error(t('OFFER.DUPLICATE_ERROR'));
        }
      } else {
        const userOffers = userDoc.data().offers || [];
        await handleFirestoreUpdate(userRef, { offers: [...userOffers, newOfferId] });
      }
      hide();
      message.success(t('OFFER.DUPLICATED_SUCCESS')); // Oferta została zduplikowana.
    } catch (error) {
      hide();
      message.error(t('OFFER.DUPLICATE_ERROR')); // Błąd podczas duplikowania oferty.
      console.error('Error duplicating offer: ', error);
    }
  }, [offer.id, t]);

  const addToFavorites = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.ADD_TO_FAVORITES);
  const onAddToFavorites = useCallback(() => {
    if (!isAuth) {
      showSignInModal({
        title: t('OFFER.SIGN_IN_TO_FAVORITE'), // Zaloguj się aby dodać Ofertę do ulubionych
        onSuccess: () => {
          location.reload();
        },
      });
    } else {
      if (isLoading) return;
      setIsLoading(true);
      setheartColor('slate');

      addToFavorites({ offerId: offer.id, uid: currentUser.uid })
        .then(() => {
          toastService.show(t('OFFER.FAVORITE_ADDED_DESC'), t('OFFER.FAVORITE_ADDED')); // Dodano do ulubionych
          setIsLoading(false);
        })
        .catch(() => {
          toastService.show(t('OFFER.ADD_TO_FAVORITE_ERROR'), t('OFFER.ERROR'), { variant: ToastVariant.ERROR }); // Cos poszło nie tak. Spróbuj później
          setIsLoading(false);
        });
    }
  }, [isLoading, currentUser, offer.id, t]);

  const [tenantFormFilled, setTenantFormFilled] = useState(false);
  const [landlordFormFilled, setLandlordFormFilled] = useState(false);

  // Function to handle deposit redirect if deposit is not confirmed
  const handleDepositRedirect = (offer, baseHostname, port, httpOrHttps) => {
    const depositUrl = `${httpOrHttps}://${baseHostname}${port}/deposit/${offer.id}`;
    window.location.href = depositUrl;
  };

  // Function to handle tenant reception form redirect
  const handleTenantReceptionRedirect = (offer, baseHostname, port, httpOrHttps, currentUser, setTenantFormFilled) => {
    if (!tenantFormFilled) {
      const receptionUrlTenant = `${httpOrHttps}://${baseHostname}${port}/contract/${offer.id}/submit/${currentUser.uid}`;
      window.location.href = receptionUrlTenant;
      setTenantFormFilled(true);
    }
  };

  // Function to handle landlord reception form redirect
  const handleLandlordReceptionRedirect = (
    offer,
    baseHostname,
    port,
    httpOrHttps,
    currentUser,
    setLandlordFormFilled
  ) => {
    if (!landlordFormFilled) {
      const receptionUrlLandlord = `${httpOrHttps}://${baseHostname}${port}/indicate-transfer-person/${offer.id}/${currentUser.uid}`;
      window.location.href = receptionUrlLandlord;
      setLandlordFormFilled(true);
    }
  };

  const redirectToProtocol = useCallback(() => {
    if (!offer?.id) {
      console.error('Offer ID is not available for redirection');
      return;
    }

    const protocolUrl = `${httpOrHttps}://${baseHostname}${port}/protocol/${offer.id}`;
    window.location.href = protocolUrl;
  }, [offer?.id]);

  // Function to handle edit or show offer
  const handleEditOrShowOffer = (onEditOffer, onShowOffer) => {
    window.location.pathname.includes(AppRoutes.MOJA_TABLICA_UNSIGNED_OFFERS) ? onEditOffer() : onShowOffer();
  };

  // Main onClick handler using the refactored functions
  const handleOnClick = (
    offer: Offer,
    baseHostname: string,
    port: string,
    httpOrHttps: string,
    currentUser: firebase.User,
    isLandlord: boolean,
    setTenantFormFilled: React.Dispatch<React.SetStateAction<boolean>>,
    setLandlordFormFilled: React.Dispatch<React.SetStateAction<boolean>>,
    onEditOffer: () => void,
    onShowOffer: () => void
  ) => {
    SHOW_LOGS && console.log('offer.receptionOfThePremise', offer?.receptionOfThePremise);
    SHOW_LOGS && console.log('offer.transferOfThePremise', offer?.transferOfThePremise);
    switch (true) {
      case offer.offerStatus.state === OfferStatusState.SIGNED &&
        isLandlord &&
        !('transferOfThePremise' in offer) &&
        'receptionOfThePremise' in offer:
        handleLandlordReceptionRedirect(offer, baseHostname, port, httpOrHttps, currentUser, setLandlordFormFilled);
        break;

      case offer.offerStatus.state === OfferStatusState.SIGNED && !isLandlord && !('receptionOfThePremise' in offer):
        handleTenantReceptionRedirect(offer, baseHostname, port, httpOrHttps, currentUser, setTenantFormFilled);
        break;

      case offer.offerStatus.state === OfferStatusState.SIGNED &&
        !isLandlord &&
        offer.offerStatus.postSigningStatus !== PostSigningStatus.DEPOSIT_CONFIRMED &&
        offer.offerStatus.postSigningStatus !== PostSigningStatus.PROTOCOL_ATTACHED &&
        offer.offerStatus.postSigningStatus !== PostSigningStatus.PROTOCOL_CONFIRMED:
        handleDepositRedirect(offer, baseHostname, port, httpOrHttps);
        break;

      case offer?.offerStatus?.state === OfferStatusState.SIGNED &&
        !['PROTOCOL_CONFIRMED'].includes(offer?.offerStatus?.postSigningStatus) &&
        'transferOfThePremise' in offer &&
        'receptionOfThePremise' in offer:
        redirectToProtocol();
        break;

      default:
        handleEditOrShowOffer(onEditOffer, onShowOffer);
        break;
    }
  };
  return (
    <>
      <Card
        onClick={() =>
          handleOnClick(
            offer,
            baseHostname,
            port,
            httpOrHttps,
            currentUser,
            isLandlord,
            setTenantFormFilled,
            setLandlordFormFilled,
            onEditOffer,
            onShowOffer
          )
        }
        className="m-2 max-w-xs hover:shadow-md"
        hoverable
        cover={
          images?.[0] ? (
            <img src={images?.[0]} style={{ height: 222 }} />
          ) : (
            <img src="/images/assets/no-image-svgrepo-com.svg" style={{ height: 222 }} />
          )
        }
        extra={
          <>
            {addToFavoritesButton && (
              <Button
                className="relative "
                onClick={(e) => {
                  e.stopPropagation();
                  onAddToFavorites();
                }}
                icon={isLoading ? <Spin /> : <HeartFilled className={`text-${heartColor}-600`} />}
              />
            )}
            {window.location.pathname.includes(AppRoutes.MOJA_TABLICA) &&
              !window.location.pathname.includes(AppRoutes.MOJA_TABLICA_FAVORITES) && (
                <DropdownButton
                  showModal={toggleModal}
                  offer={offer}
                  state={state}
                  duplicate={onDuplicateOffer}
                  showDocuments={toggleDocumentsModal}
                  isLandlord={currentUser?.uid === offer.uid}
                  onRejectProtocol={toggleRejectProtocolModal}
                  onShowOffer={onShowOffer}
                />
              )}
            {window.location.pathname.includes(AppRoutes.MOJA_TABLICA_FAVORITES) && (
              <DeleteFavorite offerId={offer.id} uid={currentUser.uid} />
            )}
          </>
        }
        title={offer.name ? offer.name : t('OFFER.NO_NAME')}>
        <Meta
          description={
            <>
              <OfferPrice price={offer.price} />
              <OfferBadges offer={offer} />
              <OfferLocationButton offer={offer} onLocationClick={onLocationClick} />
            </>
          }
        />
      </Card>
      <DeleteOfferModal handleOk={handleOk} isModalOpen={isModalOpen} toggleModal={toggleModal} />
      <DocumentsOfferModal
        isModalOpen={isDocumentsModalOpen}
        toggleModal={toggleDocumentsModal}
        offer={offer}
        isLandlord={currentUser?.uid == offer.uid}
      />
      <RejectProtocolModal
        isModalOpen={isRejectProtocolModalOpen}
        toggleModal={toggleRejectProtocolModal}
        offerId={offer.id}
        uid={currentUser?.uid}
      />
    </>
  );
};

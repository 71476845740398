import firebase from 'firebase/app';

import { FirestoreCollection } from '../firebase.models';

export async function getTotalIncome(offerId: string): Promise<number | null> {
  try {
    const firestore = firebase.firestore();
    const docRef = firestore.collection(FirestoreCollection.OFFERS_PUBLIC).doc(offerId);

    const doc = await docRef.get();
    if (!doc.exists) {
      console.error('Offer not found.');
      return null;
    }

    const offerData = doc.data();
    if (!offerData) {
      console.error('Offer data is empty.');
      return null;
    }

    const totalGrossLandlord = offerData?.income?.totalGrossLandlord;
    const totalGrossTenant = offerData?.income?.totalGrossTenant;

    const totalIncome = totalGrossLandlord + totalGrossTenant;

    return totalIncome;
  } catch (error) {
    console.error('Error fetching total income:', error);
    return null;
  }
}

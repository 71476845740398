import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Tag } from 'rsuite';
import styled from 'styled-components';

import { PageHeadingPanel } from '../../../components/Layout/styles';
import { OfferBadges } from '../../../components/OfferCard/components/OfferBadges';
import { OfferLocationButton } from '../../../components/OfferCard/components/OfferLocationButton';
import { OfferPrice } from '../../../components/OfferCard/components/OfferPrice';
import { Typography } from '../../../components/Typography/Typography';
import { Offer } from '../../../models/offers.model';
import { useAuth } from '../../Auth/AuthContext';

import { SignInToShow } from './SignInToShow';

const StyledPageHeadingPanel = styled(PageHeadingPanel)`
  margin-bottom: 40px;
  max-width: inherit;
  margin-top: 40px;
`;
const Wrapper = styled.div`
  display: flex;
`;
const IconBox = styled.div<{ offerImage?: string }>`
  height: 208px;
  width: 208px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f5fd;
  border-radius: 6px;

  ${({ offerImage }) =>
    offerImage &&
    `
      background-image: url("${offerImage}");
      background-size: cover;
    `}
`;
const Title = styled(Typography.H2)`
  line-height: 1.25;
`;
const Content = styled.div`
  display: flex;
  padding: 0 32px;
  justify-content: space-between;
  align-items: baseline;
  flex-grow: 1;

  @media (max-width: 768px) {
    padding: 0 8px;
  }
`;

interface Props {
  offer: Offer;
  offerImage?: string;
}
export const Heading: React.FC<Props> = ({ offer, offerImage }) => {
  const { t } = useTranslation();
  const { isAuth } = useAuth();
  const isMobile = window.innerWidth < 768;
  return (
    <div className="p-2">
      <StyledPageHeadingPanel shaded>
        <Wrapper>
          {isMobile ? null : offerImage ? (
            <IconBox offerImage={offerImage}></IconBox>
          ) : (
            <IconBox> {<Icon icon="building" size="5x" />}</IconBox>
          )}
          <Content>
            <div>
              <Tag color="orange">{t(`OFFER_TYPE.${offer.offerType.toUpperCase()}`)}</Tag>
              <Title>{offer.name}</Title>
              <OfferLocationButton offer={offer} onLocationClick={null} />
              <OfferBadges offer={offer} />
            </div>
            <div>
              {isAuth ? <OfferPrice price={offer.price} /> : <SignInToShow text={t('SIGN_IN_TO_SHOW.RENT_AMOUNT')} />}
            </div>
          </Content>
        </Wrapper>
      </StyledPageHeadingPanel>
    </div>
  );
};

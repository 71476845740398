import firebase from 'firebase/app';
import { useEffect, useState } from 'react';

import { Offer } from '../../models/offers.model';
import { firestore } from '../firebase';
import { FirestoreCollection } from '../firebase.models';

import { useProcessDates } from './useProcessDates.hook';

export function useGetOffer(id: string): [Offer, boolean, firebase.FirebaseError] {
  const [error, setError] = useState<firebase.FirebaseError>();
  const [loading, setLoading] = useState(false);
  const [offer, setOffer] = useState<Offer>();

  useEffect(() => {
    setLoading(true);
    setError(null);
    const unsubscribe = firestore
      .collection(FirestoreCollection.OFFERS_PUBLIC)
      .doc(id)
      .onSnapshot(
        (snapshot) => {
          const data = snapshot.data();
          setOffer(
            data
              ? ({
                  ...snapshot.data(),
                  id,
                  ...useProcessDates({
                    availability: data.availability,
                    availabilityTo: data.availabilityTo,
                    offerValidTo: data.offerValidTo,
                  }),
                } as Offer)
              : null
          );
          setLoading(false);
        },
        (e) => {
          console.log('Error', e);
          setError(e);
          setLoading(false);
        }
      );
    return () => unsubscribe();
  }, [id]);
  return [offer, loading, error];
}
